<template>
  <div>
    <template v-if="classes && classes.length > 0">
      <div class="text-center mt-3">
        <el-pagination
          background
          @current-change="setPage"
          :page-size="pageSize"
          :current-page="page"
          :total="resultsTotal"
          :pager-count="9"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
      <div class="classes-box" v-if="false">
        <div
          v-for="(course, index) in classes"
          :key="index"
          style="width: 100%;padding: 0.5rem;"
        >
          <router-link
            :to="{
              name: routerName,
              params: { id: course.id }
            }"
            style="width: 100%;text-decoration: none;"
          >
            <el-card
              class="course-card"
              :body-style="{ padding: '0px' }"
              shadow="hover"
            >
              <div class="card-cover">
                {{ course.name.substr(0, 3) }}
              </div>
              <div style="width: 100%;padding: 14px;">
                <h5 class="class-title">
                  {{ course.name }}
                </h5>
                <div class="description">
                  {{ course.description }}
                </div>
                <div class="bottom clearfix">
                  <time class="time">
                    {{ $t("TeacherCourses.Number of students") }}:
                    {{ course.student_users_count }}
                  </time>
                  <router-link
                    :to="{
                      name: routerName,
                      params: { id: course.id }
                    }"
                  >
                    <el-button type="text" class="button">
                      {{ $t("TeacherCourses.View Class") }}
                    </el-button>
                  </router-link>
                </div>
              </div>
            </el-card>
          </router-link>
        </div>
      </div>
      <el-table v-if="classes" :data="classes" stripe>
        <el-table-column
          :label="$t('TeacherCourses.Class Name')"
          min-width="150"
        >
          <template slot-scope="scope">
            <router-link
              :to="{
                name: 'TeacherCourseDetail',
                params: {
                  id: scope.row.id
                }
              }"
            >
              <b>{{ scope.row.name }}</b>
            </router-link>
          </template>
        </el-table-column>
        <!-- <el-table-column
          :label="$t('TeacherCourses.Teacher')"
          min-width="150"
          v-if="isAdmin"
        >
          <template slot-scope="scope">
            <b
              class="mr-1"
              v-for="(teacher, index) in scope.row.teacher_users"
              :key="teacher.id"
            >
              <router-link
                :to="{ name: 'AdminViewUser', params: { id: teacher.id } }"
              >
                <template v-if="index < scope.row.teacher_users.length - 1">
                  {{
                    `${user.displayName(
                      teacher.first_name,
                      teacher.last_name
                    )},`
                  }}
                </template>
                <template v-else>
                  {{
                    `${user.displayName(teacher.first_name, teacher.last_name)}`
                  }}
                </template>
              </router-link>
            </b>
          </template>
        </el-table-column> -->
        <el-table-column :label="$t('TeacherCourses.Class Description')">
          <template slot-scope="scope">
            {{ scope.row.description }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('TeacherCourses.Method')" width="80">
          <template slot-scope="scope">
            <div class="text-center text-success" style="cursor: pointer;">
              <el-tooltip
                class="mr-2"
                effect="dark"
                :content="$t('TeacherCourses.In-Person')"
                placement="top"
              >
                <span v-if="scope.row.is_online === 0">
                  <i aria-hidden="true" class="fa fa-building"></i>
                </span>
              </el-tooltip>
              <el-tooltip
                class="mr-2"
                effect="dark"
                :content="$t('TeacherCourses.Online')"
                placement="top"
              >
                <span v-if="scope.row.is_online === 1">
                  <i aria-hidden="true" class="fa fa-globe"></i>
                </span>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('TeacherCourses.Class Dates')" width="150">
          <template slot-scope="scope">
            <template v-if="scope.row.started_on">
              <span v-if="scope.row.started_on">{{
                instant.formatGoogleTime(scope.row.started_on)
              }}</span>
              <span v-if="scope.row.ended_on">
                ~
                {{ instant.formatGoogleTime(scope.row.ended_on) }}
              </span>
            </template>
            <template v-else>
              -
            </template>
          </template>
        </el-table-column>
        <el-table-column :label="$t('TeacherCourses.Class Time')" width="150">
          <template slot-scope="scope">
            <template v-if="scope.row.time_from">
              <span>{{ scope.row.time_from }}</span>
              <span v-if="scope.row.time_to">
                ~
                {{ scope.row.time_to }}
              </span>
            </template>
            <template v-else>
              -
            </template>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('TeacherCourses.Number of Students')"
          width="200"
        >
          <template slot-scope="scope">
            {{ scope.row.student_users_count }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('TeacherCourses.Action')"
          fixed="right"
          width="200"
        >
          <template slot-scope="scope">
            <el-tooltip
              class="mr-2"
              effect="dark"
              :content="$t('TeacherCourses.Invite Students')"
              placement="top"
            >
              <el-button
                type="success"
                size="mini"
                @click="setLink(scope.row.invitation_code)"
              >
                <i class="fas fa-user-plus"></i>
              </el-button>
            </el-tooltip>

            <el-tooltip
              class="mr-2"
              effect="dark"
              :content="$t('TeacherCourses.Preview')"
              placement="top"
            >
              <router-link
                :to="{
                  name: 'TeacherCourseDetail',
                  params: {
                    id: scope.row.id
                  }
                }"
              >
                <el-button type="success" size="mini">
                  <i class="fas fa-eye"></i>
                </el-button>
              </router-link>
            </el-tooltip>
            <el-tooltip
              class="mr-2"
              effect="dark"
              :content="$t('TeacherCourses.Edit Class')"
              placement="top"
            >
              <router-link
                :to="{
                  name: 'TeacherEditCourse',
                  params: {
                    id: scope.row.id
                  }
                }"
              >
                <el-button type="success" size="mini" plain>
                  <i class="fa fa-edit"></i>
                </el-button>
              </router-link>
              <!-- <router-link
                v-if="isAdmin"
                :to="{
                  name: 'AdminEditCourse',
                  params: {
                    id: scope.row.id
                  }
                }"
              >
                <el-button type="success" size="mini" plain>
                  <i class="fa fa-edit"></i>
                </el-button>
              </router-link> -->
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <div class="text-center mt-3 mb-3">
        <el-pagination
          background
          @current-change="setPage"
          :page-size="pageSize"
          :current-page="page"
          :total="resultsTotal"
          :pager-count="9"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
      <el-dialog
        class="class-link"
        :append-to-body="true"
        :title="$t('TeacherCourses.Class Invitation Link')"
        :visible.sync="showLink"
        width="50%"
      >
        <p>{{ $t("TeacherCourses.Copy and send this link to students") }}</p>
        <div class="text-center">
          <div class="referral-code">
            <a>
              <span>
                {{ link }}
              </span>
            </a>
            <el-button class="ml-3" type="success" @click="copyLink">
              <i class="fas fa-copy"></i>
              {{ $t("toefl.Copy Link") }}
            </el-button>
          </div>
        </div>
      </el-dialog>
    </template>
    <el-empty v-else description="No Data"></el-empty>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import role from "@/mixins/role.js";
import Common from "@/mixins/common.js";
import { user, instant } from "@ivy-way/material";
import baseDomainPath from "@/common/baseDomainPath";

export default {
  components: {},

  mixins: [Common, role],

  props: ["classes", "page", "resultsTotal", "pageSize", "routerName"],
  data() {
    return {
      showLink: false
    };
  },
  computed: {
    instant() {
      return instant;
    },
    user() {
      return user;
    },
    isAdmin() {
      let isAdmin = this.isRoleAdmin();
      return isAdmin;
    },
    isTeacher() {
      let isTeacher = this.isRoleTeacher();
      return isTeacher;
    }
  },
  watch: {},

  mounted() {},

  methods: {
    setLink(code) {
      this.link = `${baseDomainPath}join?code=${code}`;
      this.showLink = true;
    },
    copyLink() {
      var oInput = document.createElement("input");
      oInput.value = this.link;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.className = "oInput";
      oInput.style.display = "none";
      this.showLink = false;
      this.$message({
        message: "Copied",
        type: "success"
      });
    },
    setPage(page) {
      this.routerPush({
        params: { ...this.$route.params },
        query: { ...this.$route.query, page: page }
      });
    }
  }
};
</script>

<style scoped>
.course-card {
  cursor: pointer;
}
.card-cover {
  /* background-color: var(--themeColor);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 16 / 9;
  font-size: 4rem;
  font-weight: 900;
  padding: 1rem;
  overflow: hidden;
  white-space: nowrap; overflow: hidden; text-overflow: ellipsis; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: var(--themeColor);
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
  padding: 1rem;
  font-size: 4rem;
  line-height: 5.5rem;
  overflow: hidden;
  text-align: center;
  font-weight: 900;
  white-space: nowrap;
}
.class-title {
  margin-bottom: 0.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 1rem;
}
.description {
  height: 1rem;
  line-height: 1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #9499a0;
  white-space: pre-line;
  font-size: 0.8rem;
}

.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.classes-box {
  display: grid;
}

@media (min-width: 1560px) and (max-width: 2059.9px) and (min-width: 1560px) and (max-width: 2059.9px) {
  .classes-box {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (min-width: 1400px) and (max-width: 1559.9px) and (min-width: 1400px) and (max-width: 1559.9px) {
  .classes-box {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (min-width: 1300px) and (max-width: 1399.9px) and (min-width: 1300px) and (max-width: 1399.9px) {
  .classes-box {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 1140px) and (max-width: 1299.9px) and (min-width: 1140px) and (max-width: 1299.9px) {
  .classes-box {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 1139.9px) and (max-width: 1139.9px) {
  .classes-box {
    grid-template-columns: repeat(3, 1fr);
  }
}
.referral-code span {
  display: inline-block;
  height: 41px;
  line-height: 41px;
  padding: 0 1rem;
  font-size: 1rem;
  background: rgb(241, 243, 244);
  border-radius: 4px;
}
::v-deep .class-link .el-dialog__body {
  padding-top: 0.2rem;
}
</style>
