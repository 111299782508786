<template>
  <div class="box">
    <SATMenu active="6" />
    <div class="container">
      <h1 class="sat_left">
        <p>
          {{ $t("TeacherCourses.Classes I Created") }}
        </p>
        <router-link
          class="sat_left_button"
          :to="{ name: 'TeacherSatAddCourse' }"
        >
          <el-tooltip
            effect="dark"
            :content="$t('TeacherCourses.Create Class')"
            placement="top"
          >
            <el-button class="ml-4" type="success" size="small">
              <i class="fa fa-plus"></i>
            </el-button>
          </el-tooltip>
        </router-link>
      </h1>
      <hr />
      <div v-if="classes">
        <Classes
          :classes="classes"
          :page="page"
          :resultsTotal="resultsTotal"
          :pageSize="pageSize"
          routerName="TeacherCourseDetail"
        ></Classes>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

import SATMenu from "@/views/AllClasses/components/SATMenu.vue";
import Class from "@/apis/class.js";
import Classes from "@/views/myClasses/components/TeacherClasses.vue";
import role from "@/mixins/role.js";
import Common from "@/mixins/common.js";

export default {
  metaInfo() {
    return {
      title: `${this.$t("TeacherCourses.My Classes")} - ${this.CompanyName}`
    };
  },

  components: {
    SATMenu,
    Classes
  },

  mixins: [Common, role],

  props: [],
  data() {
    return {
      classes: null,
      resultsTotal: 0,
      pageSize: 9
    };
  },
  computed: {
    isAdmin() {
      let isAdmin = this.isRoleAdmin();
      return isAdmin;
    },
    isTeacher() {
      let isTeacher = this.isRoleTeacher();
      return isTeacher;
    },
    page() {
      let page = 1;
      if (this.$route.query.page === undefined) {
        page = 1;
      } else {
        page = this.$route.query.page;
      }
      return parseInt(page);
    }
  },
  watch: {},

  mounted() {
    this.getClasses();
  },

  methods: {
    async getClasses() {
      const res = await Class.getClassesForTeacher({
        per_page: this.pageSize,
        page: this.page
      });
      this.classes = res.session_classes.data;
      this.resultsTotal = res.session_classes.total;
    }
  }
};
</script>

<style scoped>
.sat_left {
  margin: 20px 0;
  display: flex;
  color: var(--themeColor);
  text-decoration: none;
  align-items: center;
}
.sat_left_button {
  display: flex;
  align-items: center;
}

.sat_left > span {
  font-weight: 700;
  font-size: 20px;
}
.sat_left > p {
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 0px;
}
::v-deep .sat_left a {
  text-decoration: none;
  color: white !important;
}
.course-card {
  cursor: pointer;
}
.card-cover {
  background-color: var(--themeColor);
  width: 100%;
  height: 12rem;
  font-size: 4rem;
  line-height: 12rem;
  font-weight: 900;
  color: white;
  padding: 0 1rem;
  overflow: hidden;
  text-align: center;
}
.box .container {
  padding-top: 0;
}
.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}
</style>
